*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}
img{
    width: 100%;
    height: 100%;
}
.dropdown {
    position: relative;
  }
  
  .menu1 {
    position: absolute;
  
    list-style-type: none;
    margin: 5px 0;
    padding: 0;
  
    border: 1px solid grey;
    width: 150px;
    right: 0;
  }
  
  .menu1 > li {
    margin: 0;
  
    background-color: white;
  }
  
  .menu1 > li:hover {
    background-color: lightgray;
  }
  
  .menu1 > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
  
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }
  .r{
    display: none!important;
  }