.formreg label {
    display: inline-block;
    font-size: 14px;

    /* margin-bottom: 5px; */
    /* margin: 0; */
}
.formreg button{
    border-radius: 10px;
    font-size: 10px;
    border-width: 1px;
    width: fit-content;
    height: fit-content;
}

.formreg p{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    line-height: 1.7em;
    /* color: #999; */
}
.formreg > p,.formreg>div{
    width: 32.33%;
}
.formreg input[type=radio] {
    width: 18px;
}
.formreg input[type=checkbox] {
    width: 18px;
}

.formreg input,
.formreg select{
    width: 100%;
    height: 35px;
    font-size: 13px;
    border-radius: 5px;
    padding: 3px;
    border: 2px solid rgb(0, 0, 0);
}
.react-tel-input .form-control{
    padding: 0 51px;
    width: 100%;
}
/* .main {
    width: 80%;
    position: relative;
    left: 20%;
}




.guest input,.guest select,.guest button{
    width: 23%;

} */