.main{
    width: 100%;
    height: calc(100vh);
    background: yellowgreen;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.frame {
    background-color: white;
    width: 50%;
    padding: 2rem;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.frame div {
    width: 50%;
}

input {
    width: 100%;
    padding: 4px;

}

h1 {
    margin: 10px 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
}

form p {
    margin-bottom: 12px;
}

button {
    background-color: rgb(62, 62, 247);
    color: white;
    padding: 6px;
    border-radius: 4px;
    border: none;
    box-shadow: none;
    cursor: pointer;
}

button:hover {
    background-color: rgb(77, 77, 255);
}